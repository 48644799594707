import { useState, useEffect, useCallback } from 'react';
import { WebSocketConnection } from './connection';
import type { ConnectionStatus, WebSocketMessage } from './types';

export function useWebSocket(agentId: string) {
  const [status, setStatus] = useState<ConnectionStatus>('connecting');
  const [messages, setMessages] = useState<WebSocketMessage[]>([]);
  const [connection, setConnection] = useState<WebSocketConnection | null>(null);

  useEffect(() => {
    const ws = new WebSocketConnection();

    const handleStatus = (newStatus: ConnectionStatus) => {
      setStatus(newStatus);
    };

    const handleMessage = (message: WebSocketMessage) => {
      setMessages(prev => [...prev, message]);
    };

    ws.on('status', handleStatus);
    ws.on('message', handleMessage);

    ws.connect();
    setConnection(ws);

    // Initialize connection with agent ID
    ws.send({
      type: 'init',
      agentId
    });

    return () => {
      ws.disconnect();
    };
  }, [agentId]);

  const sendMessage = useCallback((message: Omit<WebSocketMessage, 'agentId'>) => {
    connection?.send({
      ...message,
      agentId
    });
  }, [connection, agentId]);

  const clearMessages = useCallback(() => {
    setMessages([]);
  }, []);

  return {
    status,
    messages,
    sendMessage,
    clearMessages
  };
}