import { useEffect, useRef } from 'react';
import { useWebSocket } from '@/lib/websocket/hooks';
import { Loader2 } from 'lucide-react';

interface VirtualDesktopProps {
  className?: string;
  agentId: string;
}

export function VirtualDesktop({ className, agentId }: VirtualDesktopProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { status, messages } = useWebSocket(agentId);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Handle incoming frame data
    const handleFrame = (frameData: { content: string }) => {
      const img = new Image();
      img.onload = () => {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      };
      img.src = frameData.content;
    };

    // Process WebSocket messages
    messages.forEach(message => {
      if (message.type === 'frame' && message.data) {
        handleFrame(message.data);
      }
    });
  }, [messages]);

  return (
    <div className={`relative aspect-video bg-gray-900 rounded-xl overflow-hidden shadow-lg ${className}`}>
      {status !== 'connected' && (
        <div className="absolute inset-0 flex items-center justify-center bg-black/50">
          <div className="flex items-center space-x-2">
            <Loader2 className="w-8 h-8 text-white animate-spin" />
            <span className="text-white">
              {status === 'connecting' ? 'Connecting...' :
               status === 'error' ? 'Connection error' :
               status === 'failed' ? 'Connection failed' :
               'Disconnected'}
            </span>
          </div>
        </div>
      )}
      <canvas 
        ref={canvasRef}
        className="w-full h-full"
        width={800}
        height={600}
        tabIndex={0}
      />
    </div>
  );
}