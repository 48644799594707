import { BrowserEventEmitter } from './event-emitter';
import { WS_CONFIG } from './config';
import type { WebSocketMessage, ConnectionStatus } from './types';

export class WebSocketConnection extends BrowserEventEmitter {
  private ws: WebSocket | null = null;
  private reconnectAttempts = 0;
  private reconnectTimeout: number | null = null;
  private isConnecting = false;
  private pingInterval: number | null = null;
  private lastPingTime: number = 0;

  constructor(private readonly url: string = WS_CONFIG.WS_URL) {
    super();
  }

  connect(): void {
    if (this.isConnecting || this.ws?.readyState === WebSocket.CONNECTING) {
      return;
    }

    this.isConnecting = true;
    this.updateStatus('connecting');

    try {
      console.log('Connecting to WebSocket:', this.url);
      this.ws = new WebSocket(this.url);
      this.setupEventHandlers();
    } catch (error) {
      console.error('Failed to create WebSocket:', error);
      this.handleConnectionFailure();
    }
  }

  private setupEventHandlers(): void {
    if (!this.ws) return;

    this.ws.onopen = () => {
      console.log('WebSocket connected successfully');
      this.isConnecting = false;
      this.reconnectAttempts = 0;
      this.updateStatus('connected');
      this.startPingInterval();
    };

    this.ws.onmessage = (event) => {
      try {
        const message = JSON.parse(event.data) as WebSocketMessage;
        if (message.type === 'pong') {
          this.handlePong();
        } else {
          this.emit('message', message);
        }
      } catch (error) {
        console.error('Failed to parse message:', error);
      }
    };

    this.ws.onclose = (event) => {
      console.log('WebSocket closed:', event.code, event.reason);
      this.isConnecting = false;
      this.updateStatus('disconnected');
      this.stopPingInterval();
      this.handleConnectionFailure();
    };

    this.ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      this.updateStatus('error');
    };
  }

  private startPingInterval(): void {
    this.stopPingInterval();
    this.pingInterval = window.setInterval(() => {
      this.sendPing();
    }, 30000); // Ping every 30 seconds
  }

  private stopPingInterval(): void {
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
      this.pingInterval = null;
    }
  }

  private sendPing(): void {
    if (this.ws?.readyState === WebSocket.OPEN) {
      this.lastPingTime = Date.now();
      this.send({ type: 'ping' });
    }
  }

  private handlePong(): void {
    const latency = Date.now() - this.lastPingTime;
    console.log('WebSocket latency:', latency, 'ms');
  }

  private handleConnectionFailure(): void {
    if (this.reconnectAttempts >= WS_CONFIG.RECONNECT_ATTEMPTS) {
      console.log('Max reconnection attempts reached');
      this.updateStatus('failed');
      return;
    }

    const delay = Math.min(
      WS_CONFIG.BASE_DELAY * Math.pow(2, this.reconnectAttempts),
      WS_CONFIG.MAX_DELAY
    );

    console.log(`Reconnecting in ${delay}ms (attempt ${this.reconnectAttempts + 1})`);

    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
    }

    this.reconnectTimeout = window.setTimeout(() => {
      this.reconnectAttempts++;
      this.connect();
    }, delay);
  }

  private updateStatus(status: ConnectionStatus): void {
    this.emit('status', status);
  }

  send(message: WebSocketMessage): void {
    if (this.ws?.readyState === WebSocket.OPEN) {
      this.ws.send(JSON.stringify(message));
    } else {
      console.warn('Cannot send message: WebSocket not connected');
    }
  }

  disconnect(): void {
    this.stopPingInterval();
    
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
      this.reconnectTimeout = null;
    }

    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }
    
    this.removeAllListeners();
  }
}